<template>
	<div>
		<el-form label-width="auto">
			<el-form-item label="手机账户">
				<el-input disabled v-model="account.mobile" class="w50"></el-input>
			</el-form-item>
			<el-form-item label="新密码">
				<el-input v-model="account.password" class="w50"></el-input>
			</el-form-item>
			<el-form-item label="确认密码">
				<el-input v-model="account.password1" class="w50"></el-input>
			</el-form-item>
			<el-form-item label="验证码">
				<el-input v-model="account.code" class="w50">
					<span slot="append" v-if="!state" @click="send">获取验证码</span>
					<span slot="append" v-else>{{currentTime}}s 后可重新获取</span>
				</el-input>
			</el-form-item>
			<el-form-item label="">
				<el-button type="primary" class="mr20" @click="reset">提交</el-button>
				<el-button @click="clear">重置</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				state: false,		//是否开启倒计时
				currentTime: 0,
				totalTime: 60,
				recordingTime: 0, 	//记录时间变量
				account: {},
				setInt: ''
			}
		},
		methods: {
			clear(){
				this.account = {}
			},
			reset(){
				let that = this
				this.post('?server=auth.reset.password',{
					mobile: that.account.mobile,
					code: that.account.code,
					password: that.account.password,
					password1: that.account.password1,
					event: 'reset'
				}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调status
					console.log(response.data)
					// that.userInfo.mobile = response.data.mobile
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			},
			send(){
				let that = this
				// that.currentTime = that.totalTime;
				// //开始倒计时
				// that.state = true;
				// //执行倒计时
				// that.checkingTime();
				this.post('?server=sms.send',{event:'reset',mobile:this.account.mobile}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调status
					console.log(response.data)
					that.$message.success(response.data.msg)
					//把显示时间设为总时间
					that.currentTime = that.totalTime;
					//开始倒计时
					that.state = true;
					//执行倒计时
					that.checkingTime();
					// that.userInfo.mobile = response.data.mobile
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			},
			checkingTime(){
				let that = this;
				//判断是否开启
				if(this.state) {
					//判断显示时间是否已到0，判断记录时间是否已到总时间
					if(this.currentTime > 0 && this.recordingTime <= this.totalTime){
						//记录时间增加 1
						this.recordingTime = this.recordingTime + 1;
						console.log(this.recordingTime)
						//显示时间，用总时间 - 记录时间
						this.currentTime = this.totalTime - this.recordingTime;
						//1秒钟后，再次执行本方法
						if(this.recordingTime == 1){
							this.setInt = setInterval(() => {
								//定时器内，this指向外部，找不到vue的方法，所以，需要用that变量。
								that.checkingTime();
							}, 1000)
						}

					} else {
						//时间已完成，还原相关变量
						this.state = false;		//关闭倒计时
						this.recordingTime = 0;	//记录时间为0
						this.currentTime = this.totalTime;	//显示时间为总时间
						clearInterval(this.setInt)
					}
				} else {
					//倒计时未开启，初始化默认变量
					this.state = false;
					this.recordingTime = 0;
					this.currentTime = this.totalTime;
					clearInterval(this.setInt)
				}
			},
			GetUserInfo(){
				let that = this
				this.get('?server=auth.user.info.get',{}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调
					// console.log(response)
					// that.userInfo.avatar = response.data.avatar
					// that.userInfo.username = response.data.username
					that.account = response.data
					that.account.mobile = response.data.mobile
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			},
		},
		created() {
			this.$nextTick(() => {
				this.GetUserInfo()
			});
		}
	}
</script>